<template>
	<div>
		<body :class="backgrd">
			<div :style="topScaleRatio">
				<div><img src="@/assets/razorpayBrand.png" style="height: 69px; margin-left: 200px; margin-top: 34px;" /></div>
				<div class="row" style="justify-content: center;">
					<div>
						<center>
							<img v-if="success" src="@/assets/successlogo.png" style="height: 230px; margin-top: 50px;" />
							<img v-if="!success" src="@/assets/errorlogo.png" style="height: 230px; margin-top: 50px;" />
							<div style="font-size: 31px; font-weight: 800; color: white; text-size-adjust: 70%;">{{msg}}</div>
							<div v-if="success" style="font-size: 30px; font-weight: bolder; color: white; text-size-adjust: 60%;">We got you order.</div>
							<div v-if="success" style="font-size: 17px; color: white; text-size-adjust: 60%;">{{payment_ref}}</div>
							<div :style="msgStyle">{{msg2}}</div>
							<button v-if="success" style="width: 195px; height: 42px; margin-top: 12px; border: white; border-radius: 6px;"
								@click="redirect()">
								<span><img src="@/assets/done.png" height="20" style="margin-left: 7px;" /></span>
								<span style="margin-left: 9px; text-size-adjust: 60%; color: black;">Okay</span>
							</button>
							<button v-if="!success" style="width: 195px; height: 42px; margin-top: 12px; border: white; border-radius: 6px;"
								@click="revert()">
								<span><img src="@/assets/tryagain.png" height="20" style="position: absolute; margin-left: -21px;" /></span>
								<span style="margin-left: 9px; text-size-adjust: 82%; color: black;">Try again</span>
							</button>
						</center>
					</div>
				</div>
			</div>
		</body>
	</div>
</template>

<script>
	import Vue from 'vue';
  import vueResource from 'vue-resource';
  import config from '../../i18n/en.json';
  import ScreenSizeDetector from 'screen-size-detector';
  Vue.use(vueResource)

  export default {
  	created() {
  		var screenSize=new ScreenSizeDetector().width;
      var scaleRatioNumber=screenSize<600 ? (0.00125*screenSize) : 1;
      this.topScaleRatio="zoom: "+scaleRatioNumber+";";
  		if(this.$route.params.razorpay_status == 'success') {
  			this.backgrd = 'sbg';
  			this.msg = 'Thank You!';
  			this.msg2 = 'An email confirmation will be sent you shortly.';
  			this.success = true;
  			this.msgStyle = 'font-size: 20px; color: rgb(194, 187, 187); text-size-adjust: 60%;'
  			this.payment_ref = 'Reference No: '+this.$route.params.reference_number;
  		}else{
  			this.backgrd = 'ebg';
  			this.msg = 'Oops! Payment failed';
  			this.msg2 = "There might be some issues. Please try again later."
  			this.success = false;
  			this.msgStyle = 'font-size: 20px; color: rgb(194, 187, 187); width: 236px; text-size-adjust: 60%;'
  		}
  	},
  	data() {
  		return {
  			backgrd: '',
  			logo: '',
  			success: false,
  			msgStyle: '',
  			topScaleRatio: ''
  		}
  	},
  	methods: {
  		redirect() {
  			this.$router.push({ name: 'dashboard' });
  		},
  		revert() {
  			this.$router.push({ name: 'shipping' });
  		}
  	}
  }
</script>

<style>
  .sbg {
    width: 100%;
    background-image: url("../../assets/greenLine.png");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
  }

  .ebg {
    width: 100%;
    background-image: url("../../assets/redLine.png");
    background-position: left top;
    background-repeat: repeat;
    background-size: 10px;
  }
</style>